export const AUTO_DELIVERIES_VIDEO =
  'https://player.vimeo.com/video/724861871?h=574df31bc6';
export const PROJECT_BREATHE_VIDEO =
  'https://player.vimeo.com/video/537771391?h=a1d6ab9f35';
export const PROJECT_BREATHE_AQ =
  'https://www.dpdgroup.pollutrack.net/?token=6662989da3202875166fce31dc03ac7a4e7c57b33395c36cc0f9232c0b313fe2594a03f168fa9086a6be3551470014f4a24c57c384dc831b7c34d81addadabce&lang=uk-all_en';
export const PROJECT_BREATHE_PM25 = 'https://www.youtube.com/embed/GZbBLLNwDDY';

export const AVOIDING_LANDFILL_VIDEO =
  'https://www.youtube.com/embed/-wYV6uJTdic';
export const ECO_FUND_FORESTRY =
  'https://player.vimeo.com/video/691320002?h=e71cb28bdf';
export const RELOVE_VIDEO =
  'https://player.vimeo.com/video/499299518?h=c8a0089cef';

export const DOCUMENTS_FRANCIS_POPE =
  'https://www.youtube.com/embed/GZbBLLNwDDY';
export const DOCUMENTS_OLIVER_LORD =
  'https://www.youtube.com/embed/1337zjiV1NM';
export const DOCUMENTS_TOM_BYRNE = 'https://www.youtube.com/embed/yQxo7ZXeM7U';
export const DOCUMENTS_OLLY_CRAUGHAN =
  'https://www.youtube.com/embed/xQ9N5A8vKlY';
export const DOCUMENTS_GERAINT_DAVIES =
  'https://www.youtube.com/embed/mHPL6eSqtCw';
export const DOCUMENTS_ANDY_STREET =
  'https://www.youtube.com/embed/fQA0_ONpsCI';
export const DOCUMENTS_ROSAMUND_ADOO =
  'https://www.youtube.com/embed/M8X2P_WgrzM';

export const CONTACT_TRACK = 'http://track.dpd.co.uk/';
export const CONTACT_TRACK_LOCAL = 'http://track.dpdlocal.co.uk/';
export const CONTACT_PAGE =
  'https://www.dpd.co.uk/content/how-can-we-help/contact.jsp';

export const FOOTER_PRIVACY = 'https://www.dpd.co.uk/privacy_policy.jsp';
export const FOOTER_TANDC = 'https://www.dpd.co.uk/ts-and-cs.jsp';
export const FOOTER_INSTAGRAM =
  'https://www.instagram.com/official.dpd.uk/?hl=en';
export const FOOTER_LINKEDIN = 'https://www.linkedin.com/company/dpdgroupuk';
export const REFILL_APP = 'http://www.refill.org.uk/';
export const REUSE_VIDEO =
  'https://player.vimeo.com/video/499299518?h=c8a0089cef';
export const REDUCE_REUSE_RECYCLE_VIDEO =
  'https://player.vimeo.com/video/743434310?h=fba85c870c';
export const STRATEGY_VIDEO = 'https://player.vimeo.com/video/390725273';
export const RECYCLE_VIDEO =
  'https://player.vimeo.com/video/743434310?h=fba85c870c';
