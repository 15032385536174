import {
  Typography,
  Button,
  Box,
  Container,
  useMediaQuery,
} from '@mui/material';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Trackable } from '@dpdgroupuk/react-event-tracker';
import { ARTICLE } from '../../data/analytics';

import btnLeftSmall from '../../images/NewsArticles/btnLeftSmall.png';
import defaultBanner from '../../images/NewsArticles/defaultBanner.png';
import defaultImage from '../../images/Home/homeBackground.png';

import * as routes from '../../router/routes';
import { NEWS_ARTICLES } from '../../data/newsArticles';

import theme from '../../theme';
import NewsCarousel from '../newsCarousel/NewsCarousel.jsx';
import usePageWidth from '../../utils/usePageWidth';
import usePadding from '../../utils/usePadding';

const Article = () => {
  const { id } = useParams();
  const article = useMemo(
    () => NEWS_ARTICLES.find(article => id && article.id === +id),
    [id]
  );

  const navigate = useNavigate();

  return (
    <Trackable interfaceId={ARTICLE.INTERFACE_ID} loadId={ARTICLE.ON_LOAD}>
      <Container
        disableGutters
        sx={{
          backgroundImage: `url(${article.bannerImage || defaultBanner})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          minWidth: '100%',
          pt: 10,
          pb: 17,
          display: 'flex',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        {article.bannerImage && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: 'rgba(0, 0, 0, 0.6)',
            }}
          />
        )}
        <Box sx={{ width: usePageWidth(), px: usePadding() }}>
          <Button
            onClick={() => navigate(routes.HOME)}
            sx={{
              color: '#FFF',
              backgroundColor: theme.palette.primary.green,
              borderRadius: 1,
              mb: 11,
              display: 'flex',
              alignItems: 'center',
              py: '15px',
              pr: '30px',
              pl: '23px',
              '&:hover': {
                backgroundColor: theme.palette.primary.green,
                opacity: 0.8,
                color: '#FFF',
              },
            }}
          >
            <Box component="img" src={btnLeftSmall} sx={{ pr: 2, pb: 0.25 }} />
            <Typography variant="button">Back To Home</Typography>
          </Button>
          <Typography
            variant="h5"
            gutterBottom
            color={
              article.bannerImage ? '#FFF' : theme.palette.primary.accentGreen
            }
            textAlign="center"
          >
            {article.date}
          </Typography>
          <Box
            sx={{
              position: 'relative',
              zIndex: 1,
            }}
          >
            <Typography
              variant={
                useMediaQuery(theme.breakpoints.down('md')) ? 'h2' : 'h1'
              }
              color={article.bannerImage ? '#FFF' : theme.palette.primary.green}
              textAlign="center"
              sx={{ wordWrap: 'break-word' }}
            >
              {article.title || article.cardTitle}
            </Typography>

            {article.bannerImage && (
              <div
                style={{
                  position: 'absolute',
                  zIndex: -1,
                }}
              />
            )}
          </Box>
        </Box>
      </Container>
      <Container
        disableGutters
        sx={{
          width: usePageWidth(),
          px: usePadding(),
          py: 10,
        }}
      >
        <Typography variant="h4Light" color="#000" paddingBottom={2}>
          {article.subtitle}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box
            component="img"
            src={article.embedImage || article.image || defaultImage}
            sx={{ maxWidth: '80%', pb: 2 }}
          />
        </Box>
        {article.content}
      </Container>
      <NewsCarousel
        items={NEWS_ARTICLES.slice(1)}
        itemsPerPage={3}
        backScrollId={ARTICLE.BACK_SCROLL}
        nextScrollId={ARTICLE.NEXT_SCROLL}
        readMoreId={ARTICLE.READ_MORE}
      />
    </Trackable>
  );
};

export default Article;
